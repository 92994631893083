import * as React from 'react';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';
import { ActionButton, LinkButton } from '../components/Button';
import VendahLogo from '../components/icons/VendahLogo';
import { LogoContainer } from '../components/OrderStatusLayout';
import Title from '../components/Title';
import { LineItem, OrderContext } from '../container/Order.container';
import { Container, Padding, TitleContainer } from './styles';
import { getQrcode, PaymentQrcode } from '../client/vendah/orderPaymentService/payment-qrcode';
import Copy from '../components/icons/copy';
import { useLocalStorageState } from '../utils/useLocalStorage';
import { Input } from '../components';
import { isEmailValid } from '../utils/format';

const LineItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    img {
        width: 32px;
        height: 32px;
        margin-right: 16px;
    }
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 16px;
    gap: 16px;
    line-height: 14px;

    text-align: left;
`;

const NameDetailsContainer = styled(DetailsContainer)`
    background: #f9f9f9;
`;

const LineItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 16px;
`;

const QRCodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin: 10px 80px;
`;

const QRCodeButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin: 10px 40px;
`;

const EmailContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin: 16px 10px 10px 10px;
`;

const TotalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-weight: 700;
    font-size: 16px;

    padding: 16px;

    background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #ffffff;
`;

function LineItemLine({ quantity, name, image }: LineItem) {
    return (
        <LineItemContainer>
            {image ? (
                <img src={image.url} alt={image.altText} />
            ) : (
                <img alt="Placeholder" src="https://via.placeholder.com/32" />
            )}
            <p>
                {quantity} × {name}
            </p>
        </LineItemContainer>
    );
}

function PendingPage() {
    const [error, setError] = React.useState<Error>();
    const [loadingQrcode, setLoadingQrcode] = React.useState(false);
    const [paymentQrcode, setPaymentQrcode] = React.useState<PaymentQrcode>();
    const [qrcodeInvalidEmail, setQrcodeInvalidEmail] = React.useState(false);
    const [email, setEmail] = useLocalStorageState('qrcode.email');
    const [copiedMessage, setCopiedMessage] = React.useState<string>();

    const emailInvalid = React.useMemo(() => !isEmailValid(email), [email]);

    async function showQrcode(orderId: number): Promise<void> {
        console.log('showing qrcode');
        setLoadingQrcode(true);
        if (loadingQrcode) return;

        try {
            console.log('generating qrcode', orderId);
            const response: PaymentQrcode = await getQrcode(orderId.toString(), qrcodeInvalidEmail ? email : undefined);

            console.log('get qrcode response', response);
            if (response.success) {
                console.log('Showing QRCode');

                setPaymentQrcode(response);
            } else {
                if (response.errorCode === 'INVALID_EMAIL') {
                    setQrcodeInvalidEmail(true);
                } else {
                    setError(new Error('Erro ao gerar o QRCode'));
                }
            }
        } catch (error: any) {
            Sentry.captureException(error);
            setError(new Error('Erro ao gerar o QRCode'));
        } finally {
            setLoadingQrcode(false);
        }
    }

    async function copyCode(code: string) {
        try {
            await navigator.clipboard.writeText(code);
            setCopiedMessage('Código copiado com sucesso');
        } catch (error: any) {
            setCopiedMessage('Erro ao copiar o Código');
            console.error(error);
            window.dataLayer.push({ event: 'error_copy_qrcode' });
            Sentry.captureException(error);
        }
    }

    if (error) throw error;

    return (
        <OrderContext.Consumer>
            {order => {
                const {
                    order_id,
                    reseller,
                    customer_name,
                    total_price,
                    payment_url: paymentUrl,
                    line_items: lineItems,
                    order_name: orderName,
                    shipping_address: shippingAddress,
                    shipping_type: shippingType,
                } = order!;

                const totalPrice = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                    Number(total_price)
                );

                let PaymentLink;
                if (paymentUrl) {
                    PaymentLink = (
                        <LinkButton color="pink" href={paymentUrl}>
                            <Title>Pagar com Cartão, boleto etc</Title>
                        </LinkButton>
                    );
                } else {
                    PaymentLink = (
                        <LinkButton disabled>
                            Link para pagamento com cartão, boleto, etc indisponível. Por favor tente novamente mais
                            tarde.
                        </LinkButton>
                    );
                }

                const LineItems = (
                    <LineItemsContainer>
                        {lineItems.map((item, index) => (
                            <LineItemLine {...item} key={index} />
                        ))}
                    </LineItemsContainer>
                );

                let ShippingContainer;
                if (shippingType === 'Entregar para cliente') {
                    ShippingContainer = (
                        <DetailsContainer>
                            <div>
                                <strong>Forma de entrega</strong>
                                <p>Entregar no meu endereço</p>
                            </div>
                            <div>
                                <strong>Endereço de entrega</strong>
                                <p>{shippingAddress.address1}</p>
                                {shippingAddress.address2 && <p>{shippingAddress.address2}</p>}
                                <p>
                                    {shippingAddress.zip}, {shippingAddress.city} - {shippingAddress.province_code}
                                </p>
                                {shippingAddress.phone && <p>Contato: {shippingAddress.phone}</p>}
                            </div>
                        </DetailsContainer>
                    );
                } else {
                    ShippingContainer = (
                        <DetailsContainer>
                            <div>
                                <strong>Forma de entrega</strong>
                                <p>Retirar com a Revendedora</p>
                            </div>
                        </DetailsContainer>
                    );
                }

                return (
                    <Container>
                        <LogoContainer>
                            <VendahLogo />
                        </LogoContainer>
                        <TitleContainer>
                            <Title>Pedido {orderName}</Title>
                        </TitleContainer>
                        {LineItems}
                        <TotalContainer>
                            <p>Total</p>
                            <p>{totalPrice}</p>
                        </TotalContainer>
                        {!paymentQrcode?.success && (
                            <>
                                {qrcodeInvalidEmail && (
                                    <EmailContainer>
                                        <strong>Digite seu E-mail para gerar o PIX</strong>
                                        <Input
                                            id="email"
                                            value={email}
                                            label="E-mail"
                                            onValueChange={text => setEmail(text.trim())}
                                            placeholder="E-mail"
                                            invalidMessage={emailInvalid ? 'E-mail inválido' : undefined}
                                        />
                                    </EmailContainer>
                                )}
                                <ActionButton
                                    disabled={loadingQrcode || (qrcodeInvalidEmail && emailInvalid)}
                                    onPress={() => showQrcode(order_id)}
                                >
                                    <Title>{loadingQrcode ? 'Carregando...' : 'Pagar com PIX'}</Title>
                                </ActionButton>
                            </>
                        )}
                        {paymentQrcode?.success && (
                            <div>
                                {paymentQrcode.qr_code_base64 && (
                                    <QRCodeContainer>
                                        <img
                                            src={`data:image/png;base64, ${paymentQrcode.qr_code_base64}`}
                                            alt="Red dot"
                                        />
                                    </QRCodeContainer>
                                )}
                                <QRCodeButtonContainer>
                                    <ActionButton
                                        disabled={false}
                                        color={'blue'}
                                        onPress={() => copyCode(paymentQrcode.qr_code)}
                                    >
                                        <Copy />
                                        <Padding />
                                        <Title>Copiar código Pix Copia e Cola</Title>
                                    </ActionButton>
                                    {copiedMessage && <p>{copiedMessage}</p>}
                                </QRCodeButtonContainer>
                            </div>
                        )}
                        {PaymentLink}
                        <NameDetailsContainer>
                            <div>
                                <strong>Vendido por</strong>
                                <p>{reseller.name}</p>
                            </div>
                            <div>
                                <strong>Pedido de</strong>
                                <p>{customer_name}</p>
                            </div>
                        </NameDetailsContainer>
                        {ShippingContainer}
                    </Container>
                );
            }}
        </OrderContext.Consumer>
    );
}

export default PendingPage;
